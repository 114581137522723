	/*
  	Flaticon icon font: Flaticon
  	Creation date: 26/08/2020 15:28
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticons.eot");
  src: url("./Flaticons.eot?#iefix") format("embedded-opentype"),
       url("./Flaticons.woff2") format("woff2"),
       url("./Flaticons.woff") format("woff"),
       url("./Flaticons.ttf") format("truetype"),
       url("./Flaticons.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticons.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        
}

.flaticon-duplex:before { content: "\f100"; }
.flaticon-residential:before { content: "\f101"; }
.flaticon-condominium:before { content: "\f102"; }
.flaticon-apartment:before { content: "\f103"; }
.flaticon-building:before { content: "\f104"; }
.flaticon-medieval-house:before { content: "\f105"; }